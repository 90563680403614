export const text = {
    meta: {
        appName: 'EnBW MFA Auth',
    },

    pattern: {
        email: 'Bitte E-Mail-Adresse eingeben',
        password: 'Passwort entspricht nicht den Richtlinien.',
        rule: 'mindestens 8 Zeichen, Kleinbuchstaben, Großbuchstaben, Zahlen, Sonderzeichen @$!%-+*?_&',
    },

    login: {
        headline: 'Login',
        messages: {
            error: 'Ihr Anmeldung ist fehlgeschlagen, prüfen Sie Ihre Eingaben.',
            errorToken: 'Falsches MFA Token',
            changePassword: 'Passwort ändern',
            retry: 'Erneut anmelden',
            errorRedirect:
                'Bei der Anmeldung oder Weiterleitung ist etwas schief gegangen. Bitte in einiger Zeit erneut versuchen oder an den Administrator wenden.',
        },
        user: {
            label: 'Nutzername',
            placeholder: 'Bitte E-Mail-Adresse eingeben',
            error: 'E-Mail-Adresse notwendig',
        },
        password: {
            label: 'Passwort',
            placeholder: 'Bitte Passwort eingeben',
            error: 'Passwort notwendig',
        },
        submit: 'Anmelden',
    },

    confirm: {
        headline: 'Bitte neues Passwort vergeben',
        password: {
            label1: 'Passwort',
            label2: 'Passwort bestätigen',
            placeholder1: 'Bitte Passwort eingeben',
            placeholder2: 'Bitte Passwort erneut eingeben',
            error: 'Passwort notwendig',
            errorEqual: 'Passwörter stimmen nicht überein',
        },
    },

    token: {
        headline: 'MFA Token',
        field: {
            label: 'Token',
            placeholder: 'Bitte den Token aus der App eingeben',
            error: 'MFA Token notwendig',
        },
        submit: 'Bestätigen',
    },

    password: {
        headline: 'Passwort ändern',
        messages: {
            token: 'Sie haben einen Zahlencode per E-Mail an die angegebene E-Mail-Adresse bekommen. Geben Sie diesen zusammen mit dem neuen Passwort ein.',
            success: 'Passwort wurde erfolgreich verändert.',
            href: 'Bitte erneut anmelden',
        },
        user: {
            label: 'Nutzername',
            placeholder: 'Bitte E-Mail-Adresse eingeben',
            error: 'E-Mail-Adresse notwendig',
        },
        newPassword: {
            label: 'neues Passwort',
            placeholder: 'Bitte hier das neue Passwort eingeben',
            error: 'Neues Passwort eingeben',
        },
        token: {
            label: 'Verifizierungs',
            placeholder: 'Verifizierungs-Code aus der E-Mail',
            error: 'Verifizierungs-Code notwendig',
        },
        submit: 'Ändern',
    },
};

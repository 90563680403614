import * as React from 'react';

// aws
import { Amplify } from 'aws-amplify';
import {
    resetPassword,
    type ResetPasswordInput,
    confirmResetPassword,
    type ConfirmResetPasswordInput,
} from 'aws-amplify/auth';

// antd
import { Form, Input, Button } from 'antd';
import { MailOutlined, FileProtectOutlined, UserOutlined, LoadingOutlined } from '@ant-design/icons';

// configs
import UserPoolData from '../../Assets/config.jsx';
import Pattern from '../../Assets/pattern';
import { text } from '../../Assets/text.ts';

// types
import { Error } from '../../Assets/Error.ts';
import { NextStep } from '../../Assets/AmplifyNextStep.ts';

interface FieldType {
    username: string;
    confirmationCode: string;
    newPassword: string;
}

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: UserPoolData.clientId,
            userPoolId: UserPoolData.userPoolId,
        },
    },
});

export function ResetPasswordContainer() {
    const [loading, setLoading] = React.useState(false);
    const [resetPasswordState, setResetPasswordState] = React.useState(false);
    const [resetSuccess, setResetSuccess] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);

    const [form] = Form.useForm();

    async function handleReset({ username }: ResetPasswordInput) {
        try {
            const resetPasswordResponse = await resetPassword({ username });
            const { nextStep } = resetPasswordResponse;

            if (nextStep.resetPasswordStep === NextStep.CONFIRM_RESET_PASSWORD_WITH_CODE) {
                setResetPasswordState(true);
            }
        } catch (error) {
            controlError(error);
        }

        setLoading(false);
    }

    async function handleConfirmResetPassword({ username, confirmationCode, newPassword }: ConfirmResetPasswordInput) {
        try {
            await confirmResetPassword({ username, confirmationCode, newPassword });

            setResetSuccess(true);
            setResetPasswordState(false);
        } catch (error) {
            controlError(error);
        }

        setLoading(false);
    }

    function handleForm(values: ResetPasswordInput | ConfirmResetPasswordInput, resetPasswordValue: boolean) {
        setLoading(true);

        if (resetPasswordValue) {
            handleConfirmResetPassword(values as ConfirmResetPasswordInput);
        } else {
            setDisabled(true);
            handleReset(values as ResetPasswordInput);
        }
    }

    function handleSubmitButton(fieldName: string) {
        if (!resetPasswordState) {
            setDisabled(false);
        }
        if (fieldName === 'newPassword') {
            setTimeout(() => {
                setDisabled(form.getFieldError('newPassword').length > 0);
            }, 10);
        }
    }

    function controlError(error) {
        const errorType = JSON.parse(JSON.stringify(error));

        if (errorType.name === Error.UserNotFoundException) {
            window.location.href = '/';
        } else {
            console.error(error);
        }
    }

    return (
        <>
            <div className="formWrapper">
                <h1>{text.password.headline}</h1>

                {resetPasswordState && (
                    <p>
                        {text.password.messages.token}
                        <br />
                        <br />
                    </p>
                )}
                {!resetSuccess && (
                    <Form
                        name="password"
                        form={form}
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 16 }}
                        style={{ maxWidth: 600 }}
                        initialValues={{ remember: true }}
                        onFinish={(values) => handleForm(values, resetPasswordState)}
                        onFinishFailed={() => {}}
                        autoComplete="off"
                    >
                        <Form.Item<FieldType>
                            label={text.password.user.label}
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: text.password.user.error,
                                },
                                {
                                    pattern: Pattern.email,
                                    message: text.pattern.email,
                                    validateTrigger: 'onBlur',
                                },
                            ]}
                        >
                            <Input
                                prefix={<UserOutlined style={{ color: '#000000' }} />}
                                placeholder={text.password.user.placeholder}
                                onChange={() => handleSubmitButton('username')}
                            />
                        </Form.Item>

                        {resetPasswordState && (
                            <>
                                <Form.Item<FieldType>
                                    label={text.password.newPassword.label}
                                    name="newPassword"
                                    extra={<span>{text.pattern.rule}</span>}
                                    validateTrigger="onBlur"
                                    rules={[
                                        {
                                            required: true,
                                            message: text.password.newPassword.error,
                                        },
                                        {
                                            pattern: Pattern.password,
                                            message: text.pattern.password,
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        prefix={<FileProtectOutlined style={{ color: '#000000' }} />}
                                        placeholder={text.password.newPassword.placeholder}
                                        onBlur={() => handleSubmitButton('newPassword')}
                                    />
                                </Form.Item>
                                <Form.Item<FieldType>
                                    label={text.password.token.label}
                                    name="confirmationCode"
                                    rules={[
                                        {
                                            required: true,
                                            message: text.password.token.error,
                                        },
                                    ]}
                                >
                                    <Input
                                        prefix={<MailOutlined style={{ color: '#000000' }} />}
                                        placeholder={text.password.token.placeholder}
                                    />
                                </Form.Item>
                            </>
                        )}

                        <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                            <Button type="primary" htmlType="submit" disabled={disabled}>
                                {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.password.submit}
                            </Button>
                        </Form.Item>
                    </Form>
                )}

                {resetSuccess && (
                    <p>
                        {text.password.messages.success}
                        <br />
                        <br />
                        <a href="/">{text.password.messages.href}</a>
                        <br />
                        <br />
                    </p>
                )}
            </div>
        </>
    );
}

export default ResetPasswordContainer;

import * as React from 'react';

// aws
import { confirmSignIn } from 'aws-amplify/auth';

// antd
import { Form, Input, Button } from 'antd';
import { UnlockOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';

// config
import Pattern from '../../Assets/pattern';
import { text } from '../../Assets/text.ts';

// types
interface FieldType {
    username?: string;
    password1?: string;
    password2?: string;
}
type Props = {
    onSignIn: (nextStep) => void;
    onError: (error) => void;
};

export function SignInConfirm(props: Props) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);
    const [valid, setValid] = React.useState({
        password1: false,
        password2: false,
    });

    const [form] = Form.useForm();

    function comparePasswords({ getFieldValue }) {
        setDisabled(true);

        return {
            validator(_, value) {
                if (!value || getFieldValue('password1') === value) {
                    setDisabled(false);
                    return Promise.resolve();
                }
                return Promise.reject(new Error(text.confirm.password.errorEqual));
            },
        };
    }

    async function handleSignInConfirm() {
        setLoading(true);

        try {
            const confirmSignInResponse = await confirmSignIn({
                challengeResponse: form.getFieldValue('password1'),
            });

            props.onSignIn(confirmSignInResponse.nextStep);
        } catch (error) {
            setError(true);

            props.onError(error);
        }

        setLoading(false);
    }

    function handleSubmitButton(fieldName: string) {
        setTimeout(() => {
            if (fieldName === 'password2') {
                setDisabled(false);
            }

            setValid({
                ...valid,
                [fieldName]: form.getFieldError(fieldName).length === 0,
            });
        }, 10);
    }

    React.useEffect(() => {
        setDisabled(Object.values(valid).includes(false));
        console.log('');
    }, [valid]);

    return (
        <div className="formWrapper">
            <h1>{text.confirm.headline}</h1>

            {error && (
                <p className="message">
                    <WarningOutlined /> {text.login.messages.error}
                </p>
            )}

            <Form
                name="confirm"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={handleSignInConfirm}
                onFinishFailed={() => {}}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label={text.confirm.password.label1}
                    name="password1"
                    extra={<span>{text.pattern.rule}</span>}
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            message: text.confirm.password.error,
                        },
                        {
                            pattern: Pattern.password,
                            message: text.pattern.password,
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<UnlockOutlined style={{ color: '#000000' }} />}
                        placeholder={text.confirm.password.placeholder1}
                        onBlur={() => handleSubmitButton('password1')}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    label={text.confirm.password.label2}
                    name="password2"
                    rules={[
                        {
                            required: true,
                            message: text.confirm.password.error,
                        },
                        comparePasswords,
                    ]}
                >
                    <Input.Password
                        prefix={<UnlockOutlined style={{ color: '#000000' }} />}
                        placeholder={text.confirm.password.placeholder2}
                        onBlur={() => handleSubmitButton('password2')}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" disabled={disabled}>
                        {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.password.submit}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

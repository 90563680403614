import * as React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

/** Container components */
import LoginContainer from '../Containers/LoginContainer/index.tsx';
import ResetPasswordContainer from '../Containers/ResetPasswordContainer/index.tsx';

class AppRouter extends React.Component {
    router = createBrowserRouter([
        {
            path: '/',
            element: <LoginContainer />,
        },
        {
            path: '/pw',
            element: <ResetPasswordContainer />,
        },
    ]);

    render() {
        return (
            <React.StrictMode>
                <RouterProvider router={this.router} />
            </React.StrictMode>
        );
    }
}

export default AppRouter;

import * as React from 'react';
import { Link } from 'react-router-dom';

// aws
import { signIn, type SignInInput } from 'aws-amplify/auth';

// antd
import { Form, Input, Button } from 'antd';
import { ArrowRightOutlined, UserOutlined, UnlockOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';

// configs
import Pattern from '../../Assets/pattern';
import { text } from '../../Assets/text.ts';

// types
interface FieldType {
    username: string;
    password: string;
}

type Props = {
    onSignIn: (nextStep) => void;
    onError: (error) => void;
};

export function SignIn(props: Props) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);
    const [disabled, setDisabled] = React.useState(true);

    const [form] = Form.useForm();

    async function handleSignIn(values) {
        setLoading(true);

        try {
            const signInResponse = await signIn(values as SignInInput);

            props.onSignIn(signInResponse.nextStep);
        } catch (error) {
            setError(true);
        }

        setLoading(false);
    }

    function handleSubmitButton(fieldName: string) {
        setTimeout(() => {
            setDisabled(form.getFieldError(fieldName).length !== 0);
        }, 10);
    }

    return (
        <div className="formWrapper">
            <h1>{text.login.headline}</h1>

            {error && (
                <p className="message">
                    <WarningOutlined /> {text.login.messages.error}
                </p>
            )}

            <Form
                name="login"
                form={form}
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                style={{ maxWidth: 600 }}
                initialValues={{ remember: true }}
                onFinish={handleSignIn}
                onFinishFailed={() => {}}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label={text.login.user.label}
                    name="username"
                    validateTrigger="onBlur"
                    rules={[
                        {
                            required: true,
                            message: text.login.user.error,
                        },
                        {
                            pattern: Pattern.email,
                            message: text.pattern.email,
                        },
                    ]}
                >
                    <Input
                        prefix={<UserOutlined style={{ color: '#000000' }} />}
                        placeholder={text.login.user.placeholder}
                        type="email"
                        onBlur={() => handleSubmitButton('username')}
                    />
                </Form.Item>

                <Form.Item<FieldType>
                    label={text.login.password.label}
                    name="password"
                    rules={[
                        {
                            required: true,
                            message: text.login.password.error,
                        },
                    ]}
                >
                    <Input.Password
                        prefix={<UnlockOutlined style={{ color: '#000000' }} />}
                        placeholder={text.login.password.placeholder}
                    />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit" disabled={disabled}>
                        {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.login.submit}
                    </Button>
                </Form.Item>
            </Form>

            <p>
                <ArrowRightOutlined />
                &nbsp;
                <Link to="/pw">{text.login.messages.changePassword}</Link>
            </p>
        </div>
    );
}

import * as React from 'react';
import { Link } from 'react-router-dom';

// aws
import { Amplify } from 'aws-amplify';
import { signOut } from 'aws-amplify/auth';
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

// antd
import { WarningOutlined } from '@ant-design/icons';

// libs
import axios, { type AxiosResponse } from 'axios';

// components
import { SignIn } from '../SignIn/index.tsx';
import { SignInConfirm } from '../SignInConfirm/index.tsx';
import { TOTPConfirm } from '../TOTPConfirm/index.tsx';

// configs
import UserPoolData from '../../Assets/config';
import { text } from '../../Assets/text.ts';

// types
import { NextStep } from '../../Assets/AmplifyNextStep.ts';
import { State } from './types.ts';

Amplify.configure({
    Auth: {
        Cognito: {
            userPoolClientId: UserPoolData.clientId,
            userPoolId: UserPoolData.userPoolId,
        },
    },
});

type Props = {};

class LoginContainer extends React.Component<Props, State> {
    state = {
        signInPage: true,
        confirmSignInPage: false,
        TOTPConfirmPage: false,
        QRCode: '',
        errorRedirect: false,
    };

    handleRedirect = async () => {
        const token = await cognitoUserPoolsTokenProvider.getTokens()!;
        const { idToken } = token!;

        if (idToken) {
            await axios
                .post(
                    `${UserPoolData.invokeUrl}/auth`,
                    {},
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: idToken?.toString(),
                        },
                    }
                )
                .then(({ data, status }: AxiosResponse) => {
                    if (status === 200 || status === 201) {
                        window.location.href = data.Message;
                    } else {
                        this.controlError(new Error(`HTTP Status: ${this.state}`));
                    }
                })
                .catch((e) => {
                    this.setState({ errorRedirect: true });
                    this.controlError(e);
                });
        }
    };

    controlSignIn(nextStep) {
        this.setState({ signInPage: false, confirmSignInPage: false, TOTPConfirmPage: false, QRCode: '' });

        switch (nextStep.signInStep) {
            case NextStep.CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED:
                this.setState({ confirmSignInPage: true });
                break;

            case NextStep.CONTINUE_SIGN_IN_WITH_TOTP_SETUP:
                this.setState({
                    TOTPConfirmPage: true,
                    QRCode: nextStep.totpSetupDetails.getSetupUri(text.meta.appName),
                });
                break;

            case NextStep.CONFIRM_SIGN_IN_WITH_TOTP_CODE:
                this.setState({ TOTPConfirmPage: true, QRCode: '' });
                break;

            case NextStep.DONE:
                this.handleRedirect();
                break;

            default:
                this.setState({ signInPage: true });
        }
    }

    controlError(error) {
        console.error('.... ', error);
    }

    render() {
        const { signInPage, confirmSignInPage, TOTPConfirmPage, QRCode, errorRedirect } = this.state;

        // to stop MFA process if failed
        signOut();

        return (
            <>
                {signInPage && (
                    <SignIn onSignIn={this.controlSignIn.bind(this)} onError={this.controlError.bind(this)} />
                )}
                {confirmSignInPage && (
                    <SignInConfirm onSignIn={this.controlSignIn.bind(this)} onError={this.controlError.bind(this)} />
                )}
                {TOTPConfirmPage && (
                    <TOTPConfirm
                        QRCode={QRCode}
                        onSignIn={this.controlSignIn.bind(this)}
                        onError={this.controlError.bind(this)}
                    />
                )}

                {errorRedirect && (
                    <div className="formWrapper">
                        <p className="message">
                            <WarningOutlined /> {text.login.messages.errorRedirect}
                            <br />
                            <br />
                            <Link to="/" onClick={() => window.location.reload()}>
                                {text.login.messages.retry}
                            </Link>
                        </p>
                    </div>
                )}
            </>
        );
    }
}

export default LoginContainer;

import * as React from 'react';
import { Link } from 'react-router-dom';

// aws
import { confirmSignIn } from 'aws-amplify/auth';

// antd
import { Form, Input, Button } from 'antd';
import { UnlockOutlined, LoadingOutlined, WarningOutlined } from '@ant-design/icons';

// libs
import QRCode from 'qrcode.react';

// configs
import UserPoolData from '../../Assets/config.jsx';
import { text } from '../../Assets/text.ts';

// types
export interface FieldType {
    confirmationCode?: string;
}

type Props = {
    QRCode: string;
    onSignIn: (nextStep) => void;
    onError: (error) => void;
};

export function TOTPConfirm(props: Props) {
    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const [form] = Form.useForm();

    async function handleSubmitMFA() {
        setError(false);
        setLoading(true);

        try {
            const confirmSignInResponse = await confirmSignIn({
                challengeResponse: form.getFieldValue('confirmationCode') || '',
            });

            props.onSignIn(confirmSignInResponse.nextStep);
        } catch (e) {
            setError(true);

            props.onError(error);
        }

        setLoading(false);
    }

    return (
        <div className="formWrapper">
            <h1>{text.token.headline}</h1>

            {error && (
                <p className="message">
                    <WarningOutlined /> {text.login.messages.errorRedirect}
                    <br />
                    <br />
                    <Link to="/" onClick={() => window.location.reload()}>
                        {text.login.messages.retry}
                    </Link>
                </p>
            )}

            {props.QRCode && !error && (
                <div className="qrCode">
                    <QRCode value={props.QRCode} />
                </div>
            )}

            {!error && (
                <Form
                    name="token"
                    form={form}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={handleSubmitMFA}
                    onFinishFailed={() => {}}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label={text.token.field.label}
                        name="confirmationCode"
                        rules={[
                            {
                                required: true,
                                message: text.token.field.error,
                            },
                        ]}
                    >
                        <Input placeholder={text.token.field.placeholder} />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        <Button type="primary" htmlType="submit">
                            {loading ? <LoadingOutlined style={{ fontSize: 24 }} /> : text.token.submit}
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </div>
    );
}
